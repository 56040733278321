<template>
  <v-container fluid>
    <PacksAndBundlesListView></PacksAndBundlesListView>
  </v-container>
</template>

<script>
export default {
  components: {
    PacksAndBundlesListView: () => import("./packs-and-bundles/ListView.vue"),
  },
};
</script>